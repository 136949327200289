<template>
  <div
    v-if="(props.header || props.subheader) && props.headerLayout !== 100"
    :class="headerClass"
  >
    <component
      :is="`h${headerLevel}`"
      v-if="props.headerLayout !== 100"
      :id="generateUrlHash(props.header)"
      :class="[
        props.headerPosition,
        props.headerStyleClass,
        {
          [props.headerLayout]: isNaN(Number(props.headerLayout)),
        },
      ]"
    >
      <NuxtLink
        v-if="props.headerLink"
        :to="preparedLink"
        no-prefetch
      >
        <HtmlParser
          v-if="props.header"
          :html="props.header"
          tag="span"
        />
      </NuxtLink>
      <HtmlParser
        v-else
        :html="props.header"
        tag="span"
      />
    </component>
    <component
      :is="`h${headerLevel + 1}`"
      v-if="props.subheader"
    >
      {{ props.subheader }}
    </component>
  </div>
</template>

<script lang="ts" setup>
import generateUrlHash from '../../utils/generateUrlHash'
import { useUiHeader } from './useUiHeader'
import type { UiHeaderProps } from './useUiHeader'

const props = withDefaults(defineProps<UiHeaderProps>(), {
  header: '',
  headerLayout: 0,
  headerPosition: '',
  headerLink: '',
  subheader: '',
  headerStyleClass: null,
  generateId: true,
})

const { headerLevel, headerClass } = useUiHeader(props)

const preparedLink = computed(() => typeof props.headerLink === 'string' ? props.headerLink : (props.headerLink?.href ?? ''))
</script>
